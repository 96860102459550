@font-face {
  font-family: 'ABBVoice';
  src: url(../fonts/ABBvoice/ABBvoice_W_Rg.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABBVoice';
  src: url(../fonts/ABBvoice/ABBvoice_W_Md.woff);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ABBVoice';
  src: url(../fonts/ABBvoice/ABBvoice_W_Bd.woff);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ABBVoice';
  src: url(../fonts/ABBvoice/ABBvoice_W_Lt.woff);
  font-weight: 200;
  font-style: normal;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: ABBVoice, Arial, sans-serif;
}
